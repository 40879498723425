var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-clipboard-list","title":_vm.$tc('Products.title'),"loading":_vm.loader},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-btn',{directives:[{name:"can",rawName:"v-can:create",value:([_vm.$constants.PRIVILEGES.PRODUCT]),expression:"[$constants.PRIVILEGES.PRODUCT]",arg:"create"}],attrs:{"color":"cta white--text","block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.createProduct}},[_vm._v(" "+_vm._s(_vm.$tc("Products.createTitle"))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1)]},proxy:true}])}),_c('v-row',{class:_vm.products.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$t('Loader.products')}}):(!_vm.loader)?_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('bar-search',{attrs:{"search":_vm.search,"readonly":_vm.loadingDT,"searchCallback":_vm.searchProductByText,"label":_vm.$tc('Products.searchBar')},on:{"update:search":function($event){_vm.search=$event}}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"customDT",attrs:{"headers":_vm.headers,"items":_vm.products,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingDT,"footer-props":{
            itemsPerPageText: _vm.$tc('Products.productsPerPage'),
            pageText: ("{0}-{1} " + (_vm.$tc('Products.productsByPage')) + " {2}"),
            'items-per-page-all-text': _vm.$tc('Products.allProducts'),
          },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","absolute":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(" "+_vm._s(_vm.$tc("Products.emptySearch"))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(_vm._s(_vm.$tc("Products.loadingSearch")))])]},proxy:true},{key:"item.image",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.photo_preview)?_c('v-img',{staticClass:"ma-1",attrs:{"src":item.photo_preview.url,"alt":item.name,"max-height":"100px","max-width":"100px","contain":""}}):_vm._e()],1)]}},{key:"item.price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price))+" ")]}},{key:"item.total_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")((item.price * item.quantity)))+" ")]}},{key:"item.quantity",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.sale)+" / "+_vm._s(item.quantity)+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('DotsMenu',{attrs:{"options":[
                {
                  title: _vm.$tc('Action.details'),
                  icon: 'mdi-eye',
                  event: 'details',
                  permissions: {
                    list: [_vm.$constants.PRIVILEGES.PRODUCT],
                    type: 'R',
                  },
                },
                item.status.name !== _vm.$constants.STATUS.DELETED &&
                  item.name !== _vm.$constants.USER_TYPE.OWNER &&
                  item.name !== _vm.$constants.USER_TYPE.CLIENT && {
                    title: _vm.$tc('Action.update'),
                    icon: 'mdi-pencil',
                    event: 'update',
                    permissions: {
                      list: [_vm.$constants.PRIVILEGES.PRODUCT],
                      type: 'U',
                    },
                  },
                item.status.name !== _vm.$constants.STATUS.DELETED &&
                  item.sale == item.quantity && {
                    title: _vm.$tc('Action.delete'),
                    icon: 'mdi-delete',
                    event: 'delete',
                    permissions: {
                      list: [_vm.$constants.PRIVILEGES.PRODUCT],
                      type: 'D',
                    },
                  } ]},on:{"update":function($event){return _vm.editProduct(item.id)},"details":function($event){return _vm.productDetail(item.id)},"delete":function($event){return _vm.deleteProductConfirm(item)}}})]}}],null,true)})],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 error white--text"},[_vm._v(_vm._s(_vm.$tc("Products.deleteTitle")))]),_c('v-card-text',{staticClass:"py-6 text-center"},[_vm._v(" "+_vm._s(_vm.$t("Products.deleteQuestion", { name: _vm.product.name }))+" ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$tc("Action.cancel")))]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.deleteProduct}},[_vm._v(_vm._s(_vm.$tc("Action.delete")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }